import { withContexts } from '@/components/withContexts';
import LineCallbackPage from "@/lib/thirdParty/LineCallbackPage";
import AnonymousOrderPages from '@/pages/AnonymousOrderPages/AnonymousOrderPages';
import ProxyLoginPage from '@/pages/Auth/ProxyLoginPage';
import { HtmlPage } from '@/pages/FunctionalDisplayPages/HtmlPage';
import PageFormPost from '@/pages/FunctionalDisplayPages/PageFormPost';
import QRCodePage from '@/pages/FunctionalDisplayPages/QRCodePage';
import ItemDetailPage from "@/pages/ItemDetailPages/ItemDetailPage";
import PrivacyPolicyPage from '@/pages/PrivacyPages/PrivacyPolicyPage';
import SearchPage from '@/pages/SearchPages/SearchPage';
import ShoppingCartPage from '@/pages/ShoppingCartPages/ShoppingCartPage';
import TermsPage from '@/pages/TermsPages/TermsPage';
import InvoiceTemplate from '@/Templates/Shop/Pages/ShoppingCartPage/InvoiceTemplates/InvoiceTemplate';
import { Result } from 'antd';
import React from 'react';
import { IRoute } from '../interfaces/Router';
import LoginPage from "../pages/Auth/LoginPage";
import HomePage from "../pages/HomePage/HomePage";
import LandingPage from "../pages/LandingPages/LandingPage";
import MemberInfoPage from "../pages/MemberPages/MemberInfoPage";
import NotFoundPage from "../pages/NotFoundPages/NotFoundPage";
import RegistPage from "../pages/RegistPages/RegistPage";

//MicroShop Free Domain
export const domainSuffixes = ["portal.junxiaotech.com", "portal.domigo-digital.com", "localhost", "portal.localhost"];
export const getCurrentDomain = () => {
    if (typeof window !== 'undefined') {
        return window.location.hostname;
    }
    return '';  // Default value if `window` is not defined (e.g., during server-side rendering)
};
const domainMatchesAnySuffix = (currentDomain: string, suffixes: string[]) => {
    return suffixes.some(suffix => currentDomain.endsWith(suffix));
}

const currentDomain = getCurrentDomain();
export const BASE_PATH = domainMatchesAnySuffix(currentDomain, domainSuffixes)
    ? `/${window.location.pathname.split('/')[1] || 'unknown'}`
    : '';
export const BASE_WEBSOCKET_PATH =
    domainMatchesAnySuffix(currentDomain, domainSuffixes)
        ? `${currentDomain}/${window.location.pathname.split('/')[1] || 'unknown'}`
        : '';

const DevPageWrapper = ({
    deviceType,
    merchantId,
    translate, getCountrySelectionDisplayName
}) => (
    <InvoiceTemplate
        deviceType={deviceType}
        merchantId={merchantId}
        translate={translate}
        getCountrySelectionDisplayName={getCountrySelectionDisplayName}
    />
);

//BASE_PATH is required if you use free domain (MicroShop)
const routes: { [key: string]: IRoute } = {
    MerchantClosedPage: { path: `${BASE_PATH}/merchantClosed`, element: <NotFoundPage /> },
    HomePage: { path: `${BASE_PATH}/`, element: <HomePage /> },
    LandingPage: { path: `${BASE_PATH}/landing`, element: <LandingPage />, },
    MemberInfoPage: { path: `${BASE_PATH}/memberInfo`, element: <MemberInfoPage /> },
    LoginPage: { path: `${BASE_PATH}/login`, element: <LoginPage /> },
    LineCallbackPage: { path: `/line-login-callback`, element: <LineCallbackPage />, isGlobalPage: true },
    ProxyLoginPage: { path: `${BASE_PATH}/proxy-login`, element: <ProxyLoginPage />, isGlobalPage: true },
    RegisterPage: { path: `${BASE_PATH}/register`, element: <RegistPage /> },
    ItemDetailPage: { path: `${BASE_PATH}/itemDetail`, element: <ItemDetailPage /> },
    SearchPage: {
        path: `${BASE_PATH}/searchPage`,
        element: <SearchPage />
    },
    ShoppingCartPage: {
        path: `${BASE_PATH}/shoppingCart`,
        element: React.createElement(withContexts(ShoppingCartPage))
    },
    PageFormPost: { path: `${BASE_PATH}/pageFormPost`, element: <PageFormPost /> },
    QRCodePage: { path: `${BASE_PATH}/qrCode`, element: <QRCodePage /> },
    HtmlPage: { path: `${BASE_PATH}/html`, element: <HtmlPage /> },
    NotFoundPage: { path: `${BASE_PATH}/*`, element: <NotFoundPage /> },
    TermsPage: { path: `${BASE_PATH}/terms`, element: <TermsPage /> },
    PrivacyPolicyPage: { path: `${BASE_PATH}/privacy-policy`, element: <PrivacyPolicyPage /> },
    AnonymousOrderPage: { path: `${BASE_PATH}/anonymousOrder`, element: <AnonymousOrderPages /> },
    DevPage: {
        path: `${BASE_PATH}/dev`,
        element: React.createElement(withContexts(DevPageWrapper))
    },
    ErrorPage: {
        path: `${BASE_PATH}/error`,
        element: <Result
            status='error' />,
        isGlobalPage: true
    }
}

export default routes;
