import dayjs from 'dayjs';
import JSONBig from 'json-bigint';
import { LocalStorageHelper } from '../../../lib/helpers/LocalStorageHelper';

type Method = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH' | 'OPTIONS' | 'HEAD' | 'CONNECT' | 'TRACE';
type ContentType = 'application/json' | 'application/x-www-form-urlencoded' | 'multipart/form-data';

interface APIOptions {
  method?: Method;
  body?: any;
  contentType?: ContentType;
  signal?: AbortSignal;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function convertDatesToUTC(obj: any): void {
  if (Array.isArray(obj)) {
    obj.forEach(item => convertDatesToUTC(item));
  } else if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (obj[key] instanceof Date) {
          obj[key] = dayjs(obj[key]).toDate().toUTCString();
        } else if (typeof obj[key] === 'object') {
          convertDatesToUTC(obj[key]);
        }
      }
    }
  }
}

function isSimpleValue(value: any): boolean {
  return typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value instanceof Date ||
    typeof value === 'bigint';
}

function serializeValue(value: any): string {
  if (typeof value === 'bigint') {
    return value.toString();
  } else if (value instanceof Date) {
    return value.toISOString();
  } else if (typeof value === 'object' && value !== null) {
    return JSONBig.stringify(value);
  } else {
    return String(value);
  }
}

function buildQueryString(filteredBody: any): string {
  return Object.entries(filteredBody)
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(v => `${key}=${encodeURIComponent(serializeValue(v))}`);
      } else if (typeof value === 'object' && value !== null && value?.constructor?.name?.includes('BigNumber')) {
        return `${key}=${encodeURIComponent(value.toString())}`;
      } else {
        return `${key}=${encodeURIComponent(serializeValue(value))}`;
      }
    })
    .join('&');
}

async function validateJWTToken(jwtToken: string): Promise<boolean> {
  try {
    const response = await fetch(`${process.env.BASE_API_URL}/api/Token/ValidateToken`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${jwtToken}` },
    });
    if (!response.ok) return false;
    const validateResponse = await response.json();
    return validateResponse.isSuccess;
  } catch (error) {
    console.error('Error validating JWT token:', error);
    return false;
  }
}

async function handleUnauthorized(jwtToken: string): Promise<void> {
  const isValid = await validateJWTToken(jwtToken);
  if (!isValid) {
    const helper = new LocalStorageHelper();
    helper.removeItem('JWTToken');
    throw new Error('Unauthorized');
  }
}

async function parseResponse(response: Response): Promise<any> {
  const rawData = await response.text();
  try {
    return JSONBig.parse(rawData);
  } catch (err) {
    return rawData;
  }
}

const useAPI = <TData = unknown>(url: string, options: APIOptions = {}) => {
  const {
    method = 'GET',
    body,
    contentType = 'application/json',
    signal
  } = options;

  const jwtToken = localStorage.getItem('JWTToken');

  return (async (): Promise<TData> => {
    const filteredBody = body && Object.fromEntries(Object.entries(body).filter(([_, value]) => value != null));

    if (filteredBody) {
      convertDatesToUTC(filteredBody);
    }

    const queryString = filteredBody ? buildQueryString(filteredBody) : '';
    const finalUrl = method === 'GET' && filteredBody ? `${url}?${queryString}` : url;

    const headers: Record<string, string> = {
      ...(jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
    };

    if (method !== 'GET' && filteredBody) {
      headers['Content-Type'] = contentType;
    }

    if (contentType === 'multipart/form-data') {
      delete headers['Content-Type'];
    }

    try {
      const response = await fetch(finalUrl, {
        method,
        headers,
        body: contentType === 'multipart/form-data'
          ? body
          : method !== 'GET' && filteredBody
            ? JSONBig.stringify(filteredBody)
            : undefined,
        signal,
      });

      if (!response.ok) {
        if (response.status === 401) {
          await handleUnauthorized(jwtToken!);
        }
        else if (response.status === 403) {
          throw new Error('Forbidden');
        }
        throw new Error(`Network error. Status: ${response.status}`);
      }

      return await parseResponse(response) as TData;
    } catch (err) {
      if (err instanceof Error && err.name === 'AbortError') {
        throw new Error('Request was cancelled');
      }
      if (err instanceof Error && err.message === 'Unauthorized') {
        throw new Error('Unauthorized');
      }
      else if (err instanceof Error && err.message === 'Forbidden') {
        throw new Error('Forbidden');
      }
      throw new Error('Network error. Please retry later.');
    }
  })();
};

export default useAPI;