import { BASE_PATH } from "@/constants/routes";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { Flex, Typography } from 'antd';
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './TermsPage.css'; // 引入自定义的 CSS 文件

const TermsPage: React.FC = () => {
    const navigate = useNavigate();
    const { merchantPortalOptionSetting } = useContext(GlobalContext);
    const [platformName, setPlatformName] = React.useState<string>('多米格數位');

    useEffect(() => {
        if (merchantPortalOptionSetting) {
            setPlatformName(merchantPortalOptionSetting.merchantName);
        }
    }, [merchantPortalOptionSetting]);

    // 處理導航到隱私政策頁面
    const handleNavigateToPrivacy = (e: React.MouseEvent) => {
        e.preventDefault();
        navigate(`${BASE_PATH}/privacy-policy`);
    };

    return (
        <Flex data-testid="terms-page" justify="center">
            <Typography style={{ padding: '20px', maxWidth: '50%' }}>
                <Typography.Title level={1} className="title-center">會員註冊條款與免責聲明</Typography.Title>
                <Typography.Paragraph className="title-center">歡迎來到 {platformName} 。在您進行會員註冊之前，請仔細閱讀以下條款及免責聲明。註冊並使用本平台，即表示您同意遵守以下規範。</Typography.Paragraph>

                <Typography.Title level={2} className="title-center">會員責任規範</Typography.Title>
                <Typography.Paragraph className="title-center">當您註冊成為本平台會員時，您必須提供準確的個人資料，並確保該等資料的更新。每一個有效證件號碼限註冊一個會員帳號，並且您對帳號的安全性負全責，包括但不限於妥善保管您的會員帳號及密碼。如發生帳號被盜或未經授權的使用，您需立即通知我們。我們將有權暫停或終止不符合規範的會員帳號。</Typography.Paragraph>

                <Typography.Title level={2} className="title-center">會員帳號使用規範</Typography.Title>
                <Typography.Paragraph className="title-center">甲方在 {platformName} 所註冊之「會員帳號」，可用來行使會員權利。甲方須在「會員中心」的「註冊會員帳號」頁面上輸入正確的個人資料。若發現未提供正確資料或原資料未更新，乙方將暫停會員服務，直到資料正確為止。</Typography.Paragraph>

                <Typography.Title level={2} className="title-center">個人資料蒐集與使用</Typography.Title>
                <Typography.Paragraph className="title-center">
                    為提供您完善的服務，本平台將依據《個人資料保護法》蒐集及使用您的個人資料，
                    包括但不限於姓名、聯絡方式、交易記錄等。資料將用於會員管理、交易處理、
                    行銷活動及數據分析，並可能提供給相關合作廠商以完成服務。
                    更多詳情請參閱<a onClick={handleNavigateToPrivacy} href="#">隱私政策</a>。
                </Typography.Paragraph>

                <Typography.Title level={2} className="title-center">隱私保護</Typography.Title>
                <Typography.Paragraph className="title-center">我們非常重視您的隱私權，您的個人資料將僅用於提供會員服務，並以最高標準的安全措施來保護資料的完整與安全。您可隨時行使查詢、更正或刪除您的個人資料的權利，具體內容請參考隱私政策中的說明。</Typography.Paragraph>

                <Typography.Title level={2} className="title-center">智慧財產權</Typography.Title>
                <Typography.Paragraph className="title-center">本平台上的所有內容（除了特別標明屬於第三方的內容）均為 {platformName} 或其內容供應商的財產，受到版權和其他知識產權法律的保護。未經書面許可，不得覆制、修改、分發或以任何方式商業利用這些內容。</Typography.Paragraph>

                <Typography.Title level={2} className="title-center">免責條款</Typography.Title>
                <Typography.Paragraph className="title-center">在法律允許的範圍內，{platformName} 不對因使用本平台服務而導致的任何直接或間接損害負責。註冊會員即表示您了解並接受使用平台可能帶來的風險。</Typography.Paragraph>

                <Typography.Title level={2} className="title-center">條款修改</Typography.Title>
                <Typography.Paragraph className="title-center">我們保留隨時修改本條款的權利，並將在必要時提前通知您。請定期查看相關更新，繼續使用平台即表示您接受最新的條款。</Typography.Paragraph>
            </Typography>
        </Flex>
    );
};

export default TermsPage;
