import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Flex, Typography } from 'antd';
import React, { useContext, useEffect } from "react";
import './PrivacyPolicyPage.css';


const PrivacyPolicyPage: React.FC = () => {
    const { translate } = useContext(TranslationContext);
    const { merchantPortalOptionSetting } = useContext(GlobalContext);
    const [platformName, setPlatformName] = React.useState<string>('多米格數位');

    useEffect(() => {
        if (merchantPortalOptionSetting) {
            setPlatformName(merchantPortalOptionSetting.merchantName);
        }
    }, [merchantPortalOptionSetting]);

    return (
        <Flex justify="center">
            <Typography style={{ padding: '20px', maxWidth: '50%' }}>
                <Typography.Title level={1} className="title-center">隱私政策</Typography.Title>

                <Typography.Title level={2}>一、政策總覽</Typography.Title>
                <Typography.Paragraph>
                    本隱私政策旨在說明我們如何蒐集、使用、保護及分享您在使用 {platformName}（以下簡稱「本平台」）時所提供的個人資料。我們致力於保護您的隱私，並遵循相關法律規定（如《個人資料保護法》），確保您的個人資料安全。
                </Typography.Paragraph>

                <Typography.Title level={2}>二、資料的蒐集</Typography.Title>
                <Typography.Paragraph>我們蒐集的個人資料類型可能包括：</Typography.Paragraph>
                <Typography.Paragraph>
                    • 基本資訊：如姓名、性別、出生日期、聯絡電話、電子郵件地址、住址等。<br />
                    • 帳戶資訊：如會員帳號、登入資訊等。<br />
                    • 身份驗證資料：如身份證號碼、護照號碼或其他合法身份識別資料。<br />
                    • 交易資訊：如購物記錄、支付資訊及相關的訂單詳細資料。<br />
                    • 使用行為資訊：如您在本平台上的瀏覽紀錄、點擊行為及購物偏好等。
                </Typography.Paragraph>

                <Typography.Title level={2}>三、資料的使用目的</Typography.Title>
                <Typography.Paragraph>
                    • 會員管理：提供帳號管理、身份驗證及帳戶安全保障。<br />
                    • 交易處理：包括付款、物流配送、訂單管理等。<br />
                    • 客戶服務：包括處理您的問題、提供售後服務及支持。<br />
                    • 行銷活動：根據您的喜好提供個人化的行銷資訊。<br />
                    • 平台優化：進行數據分析，改善用戶體驗。<br />
                    • 法令義務：依照法律要求進行的義務。
                </Typography.Paragraph>

                <Typography.Title level={2}>四、資料安全措施</Typography.Title>
                <Typography.Paragraph>
                    我們承諾採取適當的技術與組織措施來保護您的個人資料，包括但不限於：<br />
                    • 資料加密：對敏感資料進行加密傳輸與存儲。<br />
                    • 存取控制：僅授權的工作人員可以存取您的個人資料。<br />
                    • 系統安全：定期更新和檢測系統安全漏洞。
                </Typography.Paragraph>

                <Typography.Title level={2}>五、聯繫我們</Typography.Title>
                <Typography.Paragraph>
                    如果您對本隱私政策有任何疑問，請聯繫我們<br />
                </Typography.Paragraph>

            </Typography>
        </Flex>
    );
}

export default PrivacyPolicyPage;
