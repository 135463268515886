import { IPortalMerchantThirdPartyPaymentSettingViewModel } from '@/Templates/interfaces/templatesInterfaces';
import { Button, Col, Divider, Flex, Row, Tabs } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React, { useContext, useEffect, useImperativeHandle } from 'react';
import { CheckOutContext } from '../Contexts/ICheckOutContext';
import './PaymentSelector.css';


export interface IPaymentSelectorProps {
    translate: (key: string) => string;
    messageApi: MessageInstance;
    merchantId: BigInt;
    nextStep?: () => void;
    prevStep?: () => void;
    isHideSteps?: boolean | undefined;
    orderId?: BigInt;
    paymentMainTypes: string[] | undefined;
    portalMerchantPaymentSettings: IPortalMerchantThirdPartyPaymentSettingViewModel[] | undefined;
    selectedPayment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined;
    setSelectedPayment: (payment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined) => void;
    setPaymentValues: (values: any) => void;
    sendPaymentRequest: (orderId: BigInt) => Promise<void>;
    refetchPaymentMainTypes: () => void;
    refetchPaymentSettings: () => void;
}

export interface IPaymentSelectorRef {
    reset: () => void;
}

const PaymentSelector = React.forwardRef((props: IPaymentSelectorProps, ref: React.ForwardedRef<IPaymentSelectorRef | undefined>) => {
    const {
        isOnlinePayment,
        setIsOnlinePayment,
        logiscticSupportedPaymentSettingIds
    } = useContext(CheckOutContext);

    useEffect(() => {
        props.refetchPaymentSettings();
        props.refetchPaymentMainTypes();
    }, []);

    const renderPaymentMethods = () => {
        if (!props.portalMerchantPaymentSettings || !props.paymentMainTypes) return null;

        return (
            <div>
                {props.paymentMainTypes
                    .filter(paymentMainType =>
                        props.portalMerchantPaymentSettings?.some(payment => payment.paymentMainType === paymentMainType) &&
                        paymentMainType !== 'None')
                    .map((paymentMainType) => (
                        <React.Fragment key={paymentMainType}>
                            <Divider>{props.translate(paymentMainType)}</Divider>
                            <Row justify="center" gutter={[16, 16]}>
                                <Col span={24}>
                                    <Flex justify='center' align='center' gap={'small'} wrap='wrap'>
                                        {props.portalMerchantPaymentSettings
                                            ?.filter(payment => payment.paymentMainType === paymentMainType)
                                            .map((payment) => (
                                                <Button
                                                    key={payment.thirdPartySettingId.toString()}
                                                    type={payment === props.selectedPayment ? 'primary' : 'default'}
                                                    onClick={() => {
                                                        props.setSelectedPayment(payment);
                                                        props.setPaymentValues({});
                                                    }}
                                                >
                                                    {payment.providerName}
                                                </Button>
                                            ))}
                                    </Flex>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ))}
            </div>
        );
    };

    const previousStepBtn = () => {
        props.setSelectedPayment(undefined);
        props.setPaymentValues({});
        props.prevStep && props.prevStep();
    }

    const nextBtn = () => {
        props.nextStep && props.nextStep();
    }

    const validateRequest = (): boolean => {
        return !!(props.selectedPayment && props.orderId);
    }

    useImperativeHandle(ref, () => ({
        reset: () => {
            props.setSelectedPayment(undefined);
            props.setPaymentValues({});
        }
    }), []);

    return (
        <Flex justify="center">
            <Row style={{ width: '80%' }}>
                <Col span={24}>
                    <Tabs centered defaultActiveKey="1" onChange={(key) => setIsOnlinePayment(key === '1')}>
                        <Tabs.TabPane tab={props.translate('Online Payment')} key="1">
                            {renderPaymentMethods()}
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
                <Col style={{ marginTop: '20px' }} xs={24}>
                    {!props.isHideSteps && (
                        <Flex justify="space-between">
                            <Button shape="round" danger onClick={previousStepBtn}>
                                {props.translate('Previous Step')}
                            </Button>
                            <Button shape="round" type="primary" disabled={!props.selectedPayment} onClick={nextBtn}>
                                {props.translate('Next Step')}
                            </Button>
                        </Flex>
                    )}
                    {props.orderId && (
                        <Flex justify="center" style={{ margin: '10px' }}>
                            <Button
                                shape="round"
                                type="primary"
                                disabled={!validateRequest()}
                                onClick={() => props.sendPaymentRequest(props.orderId!)}
                            >
                                {props.translate('Send Payment Request')}
                            </Button>
                        </Flex>
                    )}
                </Col>
            </Row>
        </Flex>
    );
});

export default PaymentSelector;
