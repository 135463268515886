import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { CopyrightOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React, { useContext } from 'react';

const { Footer } = Layout;

const AppFooter: React.FC = () => {
    const { isHideFooter, merchantPortalOptionSetting } = useContext(GlobalContext);
    const buildTime = process.env.BUILD_TIME;
    if (isHideFooter) {
        return null;
    }
    return (
        <Footer style={{
            backgroundColor: 'white',
            textAlign: 'center',
            position: 'relative'
        }}>
            <div>
                <CopyrightOutlined /> 2024 {merchantPortalOptionSetting?.merchantName ? merchantPortalOptionSetting?.merchantName : 'Domigo'}. Developed by Domigo Co.
            </div>
            <div style={{
                position: 'absolute',
                bottom: '5px',
                right: '10px',
                fontSize: '10px',
                color: 'gray'
            }}>
                {buildTime}
            </div>
        </Footer>
    );
};

export default AppFooter;
