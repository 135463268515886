import { BASE_PATH } from '@/constants/routes';
import { LoginTypeEnum, ThirdPartyLoginProviders } from '@/enums/Enums';
import { ILoginRequest, IPortalThirdPartyLoginRequest } from "@/interfaces/Requests";
import { useMemberLoginApi } from "@/lib/api/apis";
import { useThirdPartyLoginApi } from "@/lib/api/thirdPartyLogin";
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { DeviceType } from '@/Templates/enums/templateEnums';
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Divider, Flex, Form, Image, InputRef, Tabs } from "antd";
import { MD5 } from "crypto-js";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from 'react-query';
import { useNavigate } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import ThirdPartyLogin from "./components/ThirdPartyLogin";
// 登入頁面組件
const LoginPage = () => {
    const {
        merchantPortalOptionSetting,
        sendGAEvent,
        messageApi,
        setIsLogin,
        merchantId,
        setIsPageLoading,
        refreshShoppingCartItemCount,
        tryGetSessionId,
        deviceType,
        setIsHideNavBar,
        setIsHideFooter
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const emailRef = useRef<InputRef>(null);

    // 新增 activeTab state
    const [activeTab, setActiveTab] = useState<LoginTypeEnum>(LoginTypeEnum.Email);

    // 初始化登入請求狀態
    const [loginRequest, setLoginRequest] = useState<ILoginRequest>({
        loginType: LoginTypeEnum.Email,
        email: `${process.env.TEST_ACCOUNT}`,
        hashPassword: process.env.TEST_PASSWORD || "",
        phoneNumber: "",
        merchantId: merchantId,
        sessionId: tryGetSessionId()
    });

    console.log(process.env.TEST_PASSWORD);

    // 處理 Tab 切換
    const handleTabChange = (key: string) => {
        setActiveTab(key as LoginTypeEnum);
        setLoginRequest({
            ...loginRequest,
            loginType: key as LoginTypeEnum,
            email: key === LoginTypeEnum.Email ? loginRequest.email : "",
            phoneNumber: key === LoginTypeEnum.PhoneNumber ? loginRequest.phoneNumber : ""
        });
    };

    // 處理登入響應
    const loginResponseHandler = async (data: any, loginType: string) => {
        if (data?.isSuccess) {
            sendGAEvent('login', { method: loginType });
            localStorage.setItem("JWTToken", data.result);
            setIsLogin(true);
            messageApi.success(translate('Operation success'));
            await refreshShoppingCartItemCount(true);
            navigate(`${BASE_PATH}/`, { replace: true });
        } else {
            messageApi.error(data?.message);
        }
    };

    // 登入相關的 mutations
    const { mutate: loginMutate, isLoading: loginMutating } = useMutation(
        async (request: ILoginRequest) => {
            return await useMemberLoginApi(request);
        },
        {
            onSuccess: async (data) => {
                await loginResponseHandler(data, 'MicroShop');
            }
        }
    );

    const { mutate: thirdPartyLoginMutate, isLoading: thirdPartyLoginMutating } = useMutation(
        async (request: IPortalThirdPartyLoginRequest) => {
            window.history.replaceState({}, document.title, window.location.pathname);
            setIsPageLoading(true);
            return await useThirdPartyLoginApi(request);
        },
        {
            onSuccess: async (data, request) => {
                await loginResponseHandler(data, request.provider.toString());
            },
            onSettled: () => setIsPageLoading(false)
        }
    );

    // 第三方登入處理
    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (codeResponse) => {
            thirdPartyLoginMutate({
                merchantId,
                provider: ThirdPartyLoginProviders.Google,
                accessToken: codeResponse.code,
                sessionId: tryGetSessionId()
            });
        },
        onError: console.log
    });

    const facebookLogin = () => {
        FacebookLoginClient.login((response) => {
            if (response.status !== 'connected') {
                messageApi.error('login failed');
                return;
            }
            thirdPartyLoginMutate({
                merchantId,
                provider: ThirdPartyLoginProviders.Facebook,
                accessToken: response.authResponse?.accessToken!,
                sessionId: tryGetSessionId()
            });
        }, { scope: 'public_profile, email' });
    };

    const handleLineLogin = (token: string) => {
        thirdPartyLoginMutate({
            merchantId,
            provider: ThirdPartyLoginProviders.Line,
            accessToken: token,
            sessionId: tryGetSessionId(),
            extraData: 'https://portal.domigo-digital.com/line-login-callback'
        });
    };

    // 表單處理
    const handelChange = (type: string, value: string) => {
        setLoginRequest({ ...loginRequest, [type]: value });
    };

    const handelSubmit = () => {
        const submitRequest = {
            ...loginRequest,
            hashPassword: MD5(loginRequest.hashPassword).toString().toLowerCase()
        };
        loginMutate(submitRequest);
    };

    // 初始化 Facebook SDK
    useEffect(() => {
        FacebookLoginClient.loadSdk('en_US').then(() => {
            FacebookLoginClient.init({
                appId: `${process.env.FacebookAppId}`,
                version: 'v16.0'
            });
        });
    }, []);

    useEffect(() => {
        setIsHideNavBar(true);
        setIsHideFooter(true);
        return () => {
            setIsHideNavBar(false);
            setIsHideFooter(false);
        }
    }, []);

    return (
        <div className={`flex justify-center items-center ${deviceType === DeviceType.Mobile ? 'h-auto' : 'h-[100vh]'}`}>
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <Flex justify="center" align="middle" className="mb-6">
                    <Image
                        onClick={() => navigate(`${BASE_PATH}/`)}
                        src={merchantPortalOptionSetting?.merchantIconPath}
                        width={100}
                        height={100}
                        preview={false}
                    />
                </Flex>

                <Tabs
                    centered
                    activeKey={activeTab}
                    onChange={handleTabChange}
                    items={[
                        {
                            key: LoginTypeEnum.Email,
                            label: translate('Email Login'),
                            children: (
                                <LoginForm
                                    form={form}
                                    emailRef={emailRef}
                                    loginRequest={loginRequest}
                                    handelChange={handelChange}
                                    handelSubmit={handelSubmit}
                                    isLoading={thirdPartyLoginMutating || loginMutating}
                                    translate={translate}
                                    merchantId={merchantId}
                                    loginType={LoginTypeEnum.Email}
                                />
                            )
                        },
                        {
                            key: LoginTypeEnum.PhoneNumber,
                            label: translate('Phone Login'),
                            children: (
                                <LoginForm
                                    form={form}
                                    emailRef={emailRef}
                                    loginRequest={loginRequest}
                                    handelChange={handelChange}
                                    handelSubmit={handelSubmit}
                                    isLoading={thirdPartyLoginMutating || loginMutating}
                                    translate={translate}
                                    merchantId={merchantId}
                                    loginType={LoginTypeEnum.PhoneNumber}
                                />
                            )
                        }
                    ]}
                />

                <Divider>{translate('Third Party') + ' ' + translate('Login')}</Divider>

                <ThirdPartyLogin
                    isLoading={thirdPartyLoginMutating || loginMutating}
                    googleLogin={() => googleLogin()}
                    facebookLogin={facebookLogin}
                    handleLineLogin={handleLineLogin}
                    translate={translate}
                    merchantId={merchantId}
                    tryGetSessionId={() => tryGetSessionId().toString()}
                    deviceType={deviceType}
                />

                <Divider />
                <Flex wrap="wrap" justify="center" align="center" gap={16}>
                    {translate('New to our platform') + '?'}
                    <Button
                        type="link"
                        onClick={() => navigate(`${BASE_PATH}/register`)}
                        className="ml-2"
                    >
                        {translate('Register now')}
                    </Button>
                </Flex>
            </div>
        </div>
    );
};

export default LoginPage; 