import LineLogin from "@/lib/thirdParty/LineLogin";
import { DeviceType } from "@/Templates/enums/templateEnums";
import { FacebookOutlined, GoogleOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
// 第三方登入屬性介面
interface ThirdPartyLoginProps {
    isLoading: boolean;
    googleLogin: () => void;
    facebookLogin: () => void;
    handleLineLogin: (token: string) => void;
    translate: (key: string) => string;
    merchantId: BigInt;
    tryGetSessionId: () => string;
    deviceType: DeviceType;
}

// 第三方登入組件
const ThirdPartyLogin = ({
    isLoading,
    googleLogin,
    facebookLogin,
    handleLineLogin,
    translate,
    merchantId,
    tryGetSessionId,
    deviceType
}: ThirdPartyLoginProps) => {
    return (
        <>
            <Flex wrap="wrap" justify="center" gap={16}>
                <Button
                    loading={isLoading}
                    onClick={googleLogin}>
                    <GoogleOutlined />Google
                </Button>
                <Button
                    loading={isLoading}
                    onClick={facebookLogin}>
                    <FacebookOutlined />Facebook
                </Button>
                <LineLogin
                    tokenCallback={handleLineLogin}
                />
            </Flex>
        </>
    );
};

export default ThirdPartyLogin; 