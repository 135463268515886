import routes from '@/constants/routes';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ProxyLoginPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        const method = queryParams.get('method');

        if (token) {
            const decoded = decodeURIComponent(token);
            localStorage.setItem('JWTToken', decoded);
            const searchParams = new URLSearchParams();
            searchParams.set('token', decoded);
            if (method) {
                searchParams.set('method', method);
            }
            navigate({
                pathname: routes['LoginPage'].path,
                search: searchParams.toString()
            });
        } else {
            // Redirect to login page without query parameters
            navigate(routes['LoginPage'].path);
        }
    }, [location.search, navigate]);

    return (
        <LoadingComponent />
    );
}

export default ProxyLoginPage;
