import { ThirdPartyMessageGroupProvider } from '@/enums/Enums';
import { IPortalMerchantThirdPartyMessageGroupSettingViewModel } from '@/interfaces/VIewModels';
import { useGetMerchantThridPartyMessageGroupSettingViewModelsApi } from '@/lib/api/Portal/thridPartyMessageGroup';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { FullscreenExitOutlined, FullscreenOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Empty, FloatButton } from "antd";
import { useContext, useEffect, useState } from "react";
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import LineIcon from '../../../assets/Line/LINE_Brand_icon.png';

const FunctionalFloatButtonGroup = () => {
    const [open, setOpen] = useState(false);
    const {
        isHideFunctionalFloatButtonGroup,
        messageApi,
        merchantId,
        shoppingCartItemCount
    } = useContext(GlobalContext);
    const [merchantThirdpartyMessageGroupSettingViewModels, setMerchantThirdpartyMessageGroupSettingViewModels] = useState<IPortalMerchantThirdPartyMessageGroupSettingViewModel[]>([])
    const { mutate } = useMutation(async (merchantId: BigInt) => await useGetMerchantThridPartyMessageGroupSettingViewModelsApi(merchantId), {
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                setMerchantThirdpartyMessageGroupSettingViewModels(response.result)
            }
            else {
                messageApi.error(response.message || 'Operation failed');
            }
        }
    });
    const navigate = useNavigate();
    const navTo = (route: string) => {
        navigate(`/${merchantId}/${route}`);
        setOpen(false);
    }

    useEffect(() => {
        if (merchantId)
            mutate(merchantId);
    }, [merchantId]);

    const getIconComponent = (provider: ThirdPartyMessageGroupProvider) => {
        switch (provider) {
            case ThirdPartyMessageGroupProvider.Line:
                return <img style={{ width: '100%' }} src={LineIcon}></img>
            default:
                <Empty></Empty>
        }
    };

    const handleJoinGroupClick = (link: string) => {
        window.open(link, '_blank');
    };

    if (isHideFunctionalFloatButtonGroup) {
        return null;
    }
    return (
        <>
            <FloatButton.Group
                open={open}
                trigger="click"
                style={{ right: 24 }}
                onClick={() => setOpen(!open)}
                badge={!open ? { count: shoppingCartItemCount } : {}}
                icon={<FullscreenOutlined />}
                closeIcon={<FullscreenExitOutlined />}
            >
                <FloatButton badge={{ count: shoppingCartItemCount }} icon={<ShoppingCartOutlined />} onClick={() => navTo('shoppingCart')} />
                {
                    merchantThirdpartyMessageGroupSettingViewModels.map((setting) => {
                        if (setting.showJoinIcon) {
                            return (
                                <FloatButton
                                    key={setting.id.toString()}
                                    icon={getIconComponent(setting.provider)}
                                    onClick={() => handleJoinGroupClick(setting.joinGroupLink)}
                                />
                            );
                        }
                        return null;
                    })
                }
            </FloatButton.Group>
        </>
    );
}

export default FunctionalFloatButtonGroup;