import { BASE_PATH } from "@/constants/routes";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { MerchantPortalStyleSettingType } from "@/Templates/enums/templateEnums";
import { TemplateProps } from "@/Templates/TemplateProps";
import dynamic from 'next/dynamic';
import { useContext, useEffect, useMemo } from "react";
const LandingPage = () => {
  const {
    merchantId,
    isLogin,
    deviceType,
    messageApi,
    setMaxWidth,
    setIsHideFunctionalFloatButtonGroup,
    setIsHideNavBar,
    setIsHideFooter,
    merchantPortalOptionSetting,
    refetchMerchantPortalOptionSetting,
    merchantPortalStyleSettings,
    refetchMerchantPortalStyleSettings
  } = useContext(GlobalContext);
  const { translate } = useContext(TranslationContext);

  useEffect(() => {
    setMaxWidth('100%');
    setIsHideFunctionalFloatButtonGroup(true);
    refetchMerchantPortalOptionSetting();
    refetchMerchantPortalStyleSettings();
    return () => {
      setIsHideFunctionalFloatButtonGroup(false);
      setMaxWidth('1024px');
    }
  }, []);

  useEffect(() => {
    setIsHideNavBar(true);
    setIsHideFooter(true);
    return () => {
      setIsHideNavBar(false);
      setIsHideFooter(false);
    }
  }, [setIsHideNavBar, setIsHideFooter]);

  const TemplateComponent = useMemo(() => {
    if (merchantPortalOptionSetting?.isCustomLandingPage || merchantPortalOptionSetting?.isCustomPortalPage) {
      console.log('this merchant is using custom landing page');
      return null;
    }

    return dynamic<TemplateProps>(() =>
      import(`../../Templates/Shop/Pages/LandingPage/Template_${merchantPortalStyleSettings?.filter(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'LandingPage')[0]?.style || '0'}.tsx`),
      {
        loading: () => <LoadingComponent />,
        ssr: false
      }
    );
  }, [merchantPortalOptionSetting, merchantPortalStyleSettings]);

  if (!TemplateComponent || !merchantPortalOptionSetting) {
    return <LoadingComponent />;
  }

  return (
    <div style={{ height: '100vh', overflow: 'auto' }}>
      <TemplateComponent
        deviceType={deviceType}
        BASE_PATH={BASE_PATH}
        messageApi={messageApi}
        translate={translate}
        merchantId={merchantPortalOptionSetting.merchantId}
        portalStyleSettings={merchantPortalStyleSettings}
        portalOptionSettingViewModel={merchantPortalOptionSetting}
        config={merchantPortalStyleSettings?.filter(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'LandingPage')[0]?.styleSetting || {}}
      />
    </div>
  );
};

export default LandingPage;