import { GenderEnum, LoginTypeEnum, MemberPassOTPVerifyType } from "@/enums/Enums";
import { IAlterMemberInfoRequest, SendRegistOTPRequest } from "@/interfaces/Requests";
import { useAlterMemberInfoApi, useGetMemberInfoApi, useSendRegistOTPApi } from "@/lib/api/Portal/members";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Flex, Form, Input, Select } from "antd";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

export interface IMemberInfoProps { }

export interface IMemberInfoRef {
    onRefresh: () => void;
}

const MemberInfo = React.forwardRef((props: IMemberInfoProps, ref: React.ForwardedRef<IMemberInfoRef | undefined>) => {
    const { messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const [emailCooldown, setEmailCooldown] = useState(0);
    const [phoneCooldown, setPhoneCooldown] = useState(0);
    const { data, refetch } = useQuery("GetMemberInfo", async () => await useGetMemberInfoApi());
    const { mutate: alterMemberInfoMutate } = useMutation(async (request: IAlterMemberInfoRequest) => await useAlterMemberInfoApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success')).then(() => navigate(0));
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const { mutate: sendRegistOTPMutate, isLoading } = useMutation(async (reqeust: SendRegistOTPRequest) => await useSendRegistOTPApi(reqeust), {
        onSuccess: (response, reqeust) => {
            if (response.isSuccess) {
                // 成功時直接設定60秒冷卻
                if (reqeust.Type === MemberPassOTPVerifyType.Email) {
                    setEmailCooldown(response.result || 0);
                } else {
                    setPhoneCooldown(response.result || 0);
                }
                messageApi.success(translate('Operation success'));
            } else {
                // 失敗時設置API返回的冷卻時間
                if (reqeust.Type === MemberPassOTPVerifyType.Email) {
                    setEmailCooldown(response.result || 0);
                } else {
                    setPhoneCooldown(response.result || 0);
                }
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    // 處理倒數計時
    useEffect(() => {
        let emailTimer: NodeJS.Timeout;
        if (emailCooldown > 0) {
            emailTimer = setInterval(() => {
                setEmailCooldown(prev => prev - 1);
            }, 1000);
        }
        return () => clearInterval(emailTimer);
    }, [emailCooldown]);

    useEffect(() => {
        let phoneTimer: NodeJS.Timeout;
        if (phoneCooldown > 0) {
            phoneTimer = setInterval(() => {
                setPhoneCooldown(prev => prev - 1);
            }, 1000);
        }
        return () => clearInterval(phoneTimer);
    }, [phoneCooldown]);

    const [form] = Form.useForm();

    const memberInfoTranslate = (key: string) => {
        key = key.charAt(0).toUpperCase() + key.slice(1);
        if (key === 'Id') {
            return translate('Member ID');
        }
        if (key === "IsBanned") {
            return translate("Banned");
        }
        if (key === "PhoneNumber") {
            return translate("Phone number");
        }
        if (key === 'RegistDate') {
            return translate('Registration date')
        }
        if (key === 'TotalConsumption') {
            return translate('Total consumption')
        }
        if (key === 'TotalOrderCount') {
            return translate('Total order count')
        }
        if (key === 'LoginType') {
            return translate('LoginType')
        }
        if (key === 'MerchantId') {
            return translate('Merchant ID')
        }
        if (key === 'ThirdPartyLoginProviders') {
            return translate('ThirdPartyLoginProvider')
        }
        return translate(key);
    }

    const disableCheck = (key: string, loginType: LoginTypeEnum) => {
        key = key.charAt(0).toUpperCase() + key.slice(1);
        return !["UserName", "Birthday", "Gender"].includes(key);
    }

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            alterMemberInfoMutate(values);
        });
    }

    useEffect(() => {
        if (data?.result) {
            const localDate = new Date(data.result.birthday);
            const localDateString = `${localDate.getFullYear()}-${(localDate.getMonth() + 1).toString().padStart(2, '0')}-${localDate.getDate().toString().padStart(2, '0')}`;
            data.result.birthday = localDateString;
            form.setFieldsValue(data.result);
        }
    }, [data, form]);

    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            refetch();
        }
    }), [refetch]);

    const renderFormItem = (key: string, value: any, loginType: LoginTypeEnum) => {
        if ((key === 'thirdPartyLoginProviders' || key === 'uniqueId')) {
            if (loginType === LoginTypeEnum.ThirdPartyLogin)
                return (
                    <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                        <Input disabled={disableCheck(key, loginType)} />
                    </Form.Item>
                );
            else
                return <></>;
        }

        //ignore key 
        if (key === "hasUsedTrial" || key === "userId" || key === 'hashPassword') {
            return;
        }
        if (key === "loginType") {
            return (
                <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                    <Select disabled={disableCheck(key, loginType)}>
                        {Object.keys(LoginTypeEnum).map((type) => (
                            <Select.Option key={type} value={type}>
                                {translate(type)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            );
        }
        if (key === "gender") {
            return (
                <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                    <Select>
                        {Object.keys(GenderEnum).map((genderKey) => (
                            <Select.Option key={genderKey} value={genderKey}>
                                {translate(genderKey)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            );
        }

        if (key === "birthday") {
            return (
                <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                    <Input
                        type="date"
                        disabled={disableCheck(key, loginType)}
                        onChange={(e) => {
                            const localDate = new Date(e.target.value);
                            const utcDate = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()));
                            form.setFieldsValue({ birthday: utcDate.toISOString().split('T')[0] });
                        }}
                    />
                </Form.Item>
            );
        }
        if (key === "isBanned") {
            return (
                <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                    <Select disabled>
                        <Select.Option key="true" value={true}>{translate('Yes')}</Select.Option>
                        <Select.Option key="false" value={false}>{translate('No')}</Select.Option>
                    </Select>
                </Form.Item>
            );
        }

        if (key === 'isEmailOTPValidate') {
            return (
                <>
                    <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                        <Select disabled>
                            <Select.Option value={true}>{translate('Yes')}</Select.Option>
                            <Select.Option value={false}>{translate('No')}</Select.Option>
                        </Select>
                    </Form.Item>
                    {
                        value === false &&
                        <Form.Item key={`email-otp-button-${key}`}>
                            <Flex justify="center">
                                <Button
                                    type="primary"
                                    onClick={() => { sendRegistOTPMutate({ Type: MemberPassOTPVerifyType.Email }); }}
                                    disabled={isLoading || emailCooldown > 0}
                                    style={{ marginLeft: '10px' }}
                                >
                                    {translate('Send')} {translate('Email')} {translate('OTP')}
                                    {emailCooldown > 0 && ` (${emailCooldown}s)`}
                                </Button>
                            </Flex>
                        </Form.Item>
                    }
                </>
            );
        };

        if (key === 'isPhoneNumberOTPValidate') {
            return (
                <>
                    <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                        <Select disabled>
                            <Select.Option key="true" value={true}>{translate('Yes')}</Select.Option>
                            <Select.Option key="false" value={false}>{translate('No')}</Select.Option>
                        </Select>
                    </Form.Item>
                    {
                        value === false &&
                        <Form.Item key={`phone-otp-button-${key}`}>
                            <Flex justify="center">
                                <Button
                                    type="primary"
                                    onClick={() => { sendRegistOTPMutate({ Type: MemberPassOTPVerifyType.PhoneNumber }); }}
                                    disabled={isLoading || phoneCooldown > 0}
                                    style={{ marginLeft: '10px' }}
                                >
                                    {translate('Send')} {translate('Phone Number')} {translate('OTP')}
                                    {phoneCooldown > 0 && ` (${phoneCooldown}s)`}
                                </Button>
                            </Flex>
                        </Form.Item>
                    }
                </>
            );
        };

        return (
            <Form.Item name={key} label={memberInfoTranslate(key)} key={key}>
                <Input disabled={disableCheck(key, loginType)} />
            </Form.Item>
        );
    }

    if (data)
        return (
            <>
                <Form style={{ width: '100%' }} form={form} key="memberInfoForm">
                    {data?.result && Object.entries(data.result).map(([key, value]) => (
                        <React.Fragment key={`form-item-${key}`}>
                            {renderFormItem(key, value, data.result!['loginType'])}
                        </React.Fragment>
                    ))}
                </Form>
                <Flex justify="center">
                    <Button type="primary" onClick={handleSubmit}>
                        {translate("Save")}
                    </Button>
                </Flex>
            </>
        );
});

export default MemberInfo;
