import * as antd from "antd";
import React from "react";

const LoadingComponent = React.memo(() => {
    return (
        <antd.Row>
            <antd.Col span={24}>
                <antd.Flex justify="center" align="center">
                    <antd.Skeleton active paragraph={{ rows: 4 }} />
                </antd.Flex>
            </antd.Col>
        </antd.Row>
    );
});
export default LoadingComponent;